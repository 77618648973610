import { Route, Routes } from "react-router-dom";
import DashBoard from "./DashBoard";
import GroupDetail from "./Group/Detail";
import Menu from "./Layout/Menu";

function App() {

  return (<>
    <Menu />
    <div className="App px-10 py-5 bg-[#f5f5f5] h-[calc(100vh-64px)] overflow-auto">
      <Routes>
        <Route path="/" element={<DashBoard />} />
        <Route path="/group/:groupId" element={<GroupDetail />} />
        <Route path="/*" element={<DashBoard />} />
      </Routes>
    </div>
  </>
  );
}

export default App;
