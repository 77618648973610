export const COLOR_CHART = [
    'black',
    'red',
    'blue',
    'brown',
    'gray',
    'pink',
    'orange',
    'green',
    'purple'
]